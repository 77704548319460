<template>
  <ion-header>
    <ion-toolbar>
           
      <ion-button @click="closeModal()">Close</ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card @click="validate(doc)" v-for="(doc,i) in content" :key="i">
            <ion-card-header>{{doc.nomInput}} {{doc.prenomInput}}</ion-card-header> 
            <ion-card-content>{{doc.phoneInput}}</ion-card-content>   
    </ion-card> 
  </ion-content>
</template>

<script>
import {modalController, IonContent, IonHeader,IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    content:{type:Object}
  },
  data() {
    return {
      
    }

  },
  methods:{
     async closeModal(){
          await modalController.dismiss()
      },
      async validate(data){
        
          this.$store.commit('setSearchPatient',data)
          //console.log(this.$store.state.searchPatient)
            await modalController.dismiss()
      }
  },
  components: { IonContent, IonHeader, IonToolbar }
});
</script>