<template>
<div> 
  <div v-show="$store.state.collectionRdv.length > 2">
    <h1 v-if="$store.state.user.email != 'remipradere@gmail.com'">
      Vous ne pouvez pas acceder a cette page
    </h1>
    <div v-if="$store.state.user.email == 'remipradere@gmail.com' ">
      <ion-input
        v-model="nom"
        @keyup="searchNameOnPatient(nom)"
        placeholder="Rechercher...."
      ></ion-input>
      <ion-card v-for="(doc,index) in result" :key="index">
          <ion-card-header>
              <ion-card-title>
                {{doc.nom}} {{doc.prenom}}
              </ion-card-title>
                <ion-card-subtitle>{{doc.telephone}}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
              {{doc.total}}
          </ion-card-content>
      </ion-card>

    
    </div>
  </div>
  <div v-show="$store.state.collectionRdv.length == null">
          <h2>loading</h2>
  </div>
  </div>
</template>

<script>
import {

  IonCard,
  IonCardHeader,
  IonCardContent,
  IonInput,
 
} from "@ionic/vue";
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
export default defineComponent({
    components:{
        IonInput,IonCard,IonCardHeader,IonCardContent
    },
  setup() {
    const nom = ref("");
    const result = ref("")
    const store = useStore();

    const searchNameOnPatient = (data) => {
      console.log(store.state.collectionRdv)
      ///on y va pour la fonction
      let stop = 0;
    const results=[]
      //console.log(data.toLowerCase());
      store.state.collectionRdv.forEach((element) => {
        const name = element.nom.toLowerCase();

        const inputLowerCase = data.toLowerCase();

        if (name.includes(inputLowerCase) && stop != 6) {
          results.push({
            id: element.id,
            nom: element.nom,
            prenom: element.prenom,
            telephone: element.telephone,
            total:element.total
          });
          stop++;
        }
      });
  
        result.value= results
      //console.log(result.value);
    };
    return { nom, result,searchNameOnPatient };
  },
});
</script>

<style scoped>
ion-input {
  background-color: white;
}
</style>