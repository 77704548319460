<template>
  <form ref="form" @submit.prevent="sendEmail">
        <input type="text" name="toNom" :value="hello.nom" hidden>
        <input type="text" name="toPrenom" :value="hello.prenom" hidden>
        <input type="email" name="toEmail" :value="hello.email" hidden>
        <input type="text" name="toTotal" :value="hello.total" hidden>
        <ion-button>
    <input style="color:white;background-color:navy" type="submit" value="Envoyer par mail">
    </ion-button>
  </form>  
</template>

<script>
import emailjs from 'emailjs-com';

export default {

    props:['hello'],
  methods: {
    sendEmail() {
        console.log('ok')
      emailjs.sendForm('service_zrwrg0c', 'template_k3lromv', this.$refs.form, 'user_ncZ1rY55VP4EaHXzwOWiS')
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
  },
    mounted(){
        //console.log('hello')
        // setTimeout(()=>{
        //     this.sendEmail()
        // },3000)
    }
}
</script>

<style scoped>
   
</style>