<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Agenda</ion-card-title>
      <ion-card-title>
        jour:
        {{ day }}
      </ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ion-buttons>
        <ion-button @click="substractDay">Avant</ion-button>
        <ion-button @click="addDay">Apres</ion-button>
      </ion-buttons>
      <ion-buttons>
        <ion-button @click="goListHeureRdv"> Valider </ion-button>
      </ion-buttons>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonButtons,
  IonButton,
  IonCardTitle,
} from "@ionic/vue";
import { ref } from "vue";
import * as moment from "moment";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "AccueilAgenda",
  components: { IonCard, IonButtons, IonButton, IonCardContent, IonCardTitle },
  setup() {
    moment.locale("fr");
    //jour de reference
    const now = ref(moment());
    const day = ref("");
    //console.log("diffetets test de moment   ");

    //console.log( moment().fromNow())
    //console.log( moment("20231212","YYYYMMDD").fromNow() )
    //console.log( moment("20201212","YYYYMMDD").fromNow() )
    //console.log( moment("lundi 23 août 2020","dddd D MMMM YYYY").fromNow() )
    const store = useStore();
    if (store.state.day != "") {
      day.value = store.state.day;
    } else {
      day.value = now.value.format("dddd D MMMM YYYY");
    }
    if (day.value.includes("dimanche") == true) {
      now.value = now.value.add(1, "d");
      day.value = now.value.format("dddd D MMMM YYYY");
      //si on a dépassé la date du jour
    }

    //add day
    const addDay = () => {
      now.value = now.value.add(1, "d");
      day.value = now.value.format("dddd D MMMM YYYY");
      if (day.value.includes("dimanche") == true) {
        now.value = now.value.add(1, "d");
        day.value = now.value.format("dddd D MMMM YYYY");
        //si on a dépassé la date du jour
      }
    };
    const substractDay = () => {
      now.value = now.value.subtract(1, "d");
      day.value = now.value.format("dddd D MMMM YYYY");
      if (day.value.includes("dimanche") == true) {
        now.value = now.value.subtract(1, "d");
        day.value = now.value.format("dddd D MMMM YYYY");
        //si on a dépassé la date du jour
      }
    };
    const route = useRouter();

    const goListHeureRdv = () => {
      store.commit("setDay", day.value);
      route.push("/listheurerdv/" + day.value);
    };
    return { now, day, addDay, substractDay, goListHeureRdv };
  },
};
</script>