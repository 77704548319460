<template>
<div v-show="isPending">
    <ion-row>
        loading    
    </ion-row>
</div>
<div>
<ion-item>
     <ion-label color="secondary">Email</ion-label>
    <ion-input type="email"  v-model="email"></ion-input>
</ion-item>
<ion-item>
     <ion-label color="secondary">Nom</ion-label>
    <ion-input type="text" v-model="nom"/>
</ion-item>
<ion-item>
     <ion-label color="secondary">Prenom</ion-label>
    <ion-input type="email" v-model="prenom"/>
</ion-item>
<ion-item>
     <ion-label color="secondary">Telephone</ion-label>
    <ion-input type="phone" v-model="telephone"/>
</ion-item>
<ion-item>    
     <ion-label color="secondary">Password</ion-label>
    <ion-input type="password" v-model="password"/>
</ion-item>
    <div class="error">{{error}}</div>
    <ion-button @click="handleSubmit">Sign up</ion-button>
</div>
</template>
<script>
import {timestamp} from '@/firebase/config'
import useCollection from "@/composables/useCollection";
import {ref,defineComponent} from 'vue';
//import du composable
import useSignup from '../../composables/useSignup'
import {IonButton,IonItem,IonInput} from "@ionic/vue"
export default defineComponent({
    components:{
        IonButton,IonItem,IonInput
    },
    setup(){
        const nom =ref('')
        const prenom =ref('')
        const telephone =ref('')
    
        const email = ref('')
        const password = ref('')
        //initialisation des valeurs error et la fonction pour signup
        const {error,signup,isPending} = useSignup()
        const {  addDoc } = useCollection("list");

        const handleSubmit = async() => {
            //console.log(email)
            await signup(email.value,password.value,nom.value)
            await addDoc({
                nom:nom.value,
                prenom:prenom.value,
                telephone:telephone.value,
                email:email.value,
                reservation:0,
                createdAt:timestamp()
                }) 
        }

        return {isPending,nom,prenom,email,password,handleSubmit,error,telephone}
    }

});
</script>

<style >
</style>