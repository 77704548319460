import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config.ts'

const getCollection = (collection, email) => {

    const document = ref(null)
    const error = ref(null)
    //console.log()
    // register the firestore collection reference
    const collectionRef = projectFirestore.collection(collection)
        .orderBy('createdAt')

    const unsub = collectionRef.onSnapshot(snap => {
        const results = []
        const result = []
        snap.docs.forEach(doc => {
            // must wait for the server to create the timestamp & send it back
            doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })
        });

        // update values
        results.forEach(data => {
            if (email == data.email) {
                result.push({
                    nom: data.nom,
                    prenom: data.prenom,
                    email: data.email,
                    telephone: data.telephone,
                    id: data.id,
                    reservation: data.reservation,
                    createdAt: data.createdAt
                })
            }
        })
        document.value = result[0]
        //console.log(document.value)
        //console.log(document.value.nom)
        error.value = null
    }, err => {
        //console.log(err.message)
        document.value = null
        error.value = 'could not fetch the data'
        console.log(err)
    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub());
    });

    return { error, document }
}

export default getCollection