<template>
  <ion-grid>
   
    <ion-row v-show="$store.state.blank">
      <ion-text>loading component</ion-text>
    </ion-row>
    <ion-row class="opacity ion-justify-content-center">
      <!-- {{ user.email }}  cle:  {{user.uid}} -->
      <h1 class="ion-text-center">
        Bienvenue sur le site de reservation en ligne de Mr PRADERE Rémi
        Pédicure Podologue
      </h1>
      <h2 class="ion-text-center ion-margin-bottom">
        Cliquez sur Prendre Rdv pour pouvoir choisir votre rendez vous
      </h2>
    </ion-row>
    <ion-row> </ion-row>

    <ion-row
      class="
        opacity
        ion-margin-top ion-margin-bottom ion-justify-content-center
      "
    >
      <ion-button
        v-if="$store.state.user.email == 'remipradere@gmail.com'"
        color="primary"
        @click="goAgenda"
      >
        Agenda
      </ion-button>
      <ion-button
        v-if="$store.state.user.email == 'remipradere@gmail.com'"
        color="primary"
        @click="goListRdv"
      >
        List rdv
      </ion-button>
      <ion-button color="primary" @click="goHoraires"> Prendre Rdv </ion-button>
      <ion-button @click="$emit('do-logout', null), goHome"
        >Se deconnecter</ion-button
      >
    </ion-row>
  </ion-grid>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { IonButton } from "@ionic/vue";
import { onMounted } from "vue";
export default {
  components: { IonButton },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["do-logout"],
  setup() {

    


    const router = useRouter();
    const store = useStore();

    const doLoggout = async () => {
      await store.dispatch("logout");
    };
    const goHoraires = () => {
      router.push({ path: "horaires" });
    };
    const goAgenda = () => {
    
      router.push({ name: "Folder", params: { id: "agenda" } });
    };
    const goListRdv = () => {
      router.push({ name: "Folder", params: { id: "list" } });
    };
    const goHome = () => {
        
      setTimeout(() => {
        router.push({ name: "Folder", params: { id: "Inbox" } });
      }, 300);
    };
  
    return { doLoggout, goHoraires, goAgenda, goListRdv, goHome };
  },
};
</script>

<style scoped>
.active {
  position: absolute;
  color: black;
  width: 100%;
  background-color: white;
  height: 100%;
  z-index: 11111111111111111111111111;
  top: 0;
  left: 0;
}
.notActive {
  display: none;
}
ion-buttons {
  margin: 20px;
}

.jCenter {
  justify-content: center;
}

.opacity {
  animation: opacity 1s forwards;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>