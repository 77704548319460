<template>
  <ion-item>
    <ion-label color="secondary">Email</ion-label>
    <ion-input    
      type="email"
      v-model="credentials.email"
    ></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>Password</ion-label>
    <ion-input type="password" v-model="credentials.password"></ion-input>
  </ion-item>
  <ion-button v-show="loading == false" @click="loading=true;$emit('do-login', credentials)"> Login </ion-button>
  <ion-button v-show="loading == true"> Patientez </ion-button>
</template>

<script lang="ts">
import { ref } from "vue";
import {IonButton,IonItem,IonInput} from "@ionic/vue"
export default {
    components:{
        IonItem,IonInput,IonButton
    },
  emits: ["do-login"],
  setup() {
    const loading = ref(false)
    const credentials = ref<any>(
      {
        email: "",
        password: "",
      });
    return { loading,credentials };
  },
};
</script>

<style>
</style>