<template>
  <!-- <h1 class="ion-text-center font30">Rendez vous sans inscription</h1> -->
  <ion-card>
    <ion-card-header v-if="showRdvAccueil == true">
      <ion-card-title class="opacity">
        <h2>Choisissez le jour où vous voulez consulter?</h2>

        <ion-row class="justifyCenter">
          <ion-button @click="substractDay">Avant</ion-button>
          <ion-button @click="addDay">Apres</ion-button>
        </ion-row>
        <h2 class="font30">{{ time.format.value }}</h2>
        <!-- variable par rapport a disponibilite -->
        <ion-text class="ion-text-center" v-if="nbRdv >= 3" color="success">
          <h3>il y a {{ nbRdv }} disponibilités</h3>
        </ion-text>
        <ion-text
          class="ion-text-center"
          v-if="nbRdv < 3 && nbRdv != 0"
          color="warning"
        >
          <h3>il y a {{ nbRdv }} disponibilites</h3>
        </ion-text>
        <ion-text class="ion-text-center" v-if="nbRdv == 0" color="danger">
          <h3>Pas de disponibilité ce jour</h3>
        </ion-text>
      </ion-card-title>
      <ion-row class="justifyCenter">
        <ion-card-subtitle class="justifyCenter">
          <ion-button v-if="nbRdv !== 0" @click="showSlot">
            voir Horaires
          </ion-button>
        </ion-card-subtitle>
      </ion-row>
    </ion-card-header>

    <ion-card-content>
      <template v-if="showRdv == true">
        <ion-row class="opacity ion-padding-top jCenter">
          <ion-card-title class="ion-text-center font30">{{
            time.format.value
          }}</ion-card-title>
        </ion-row>
        <ion-row class="opacity ion-padding-bottom jCenter">
          <ion-button
          id="opacity5"
            class="ion-margin-top ion-margin-bottom"
            @click="
              showRdv = false;
              showRdvAccueil = true;
            "
          >
            Changer de Jour
          </ion-button>
        </ion-row>

        <ion-row v-if="showPendingRdv == true">
          <h2 class="font30 opacity">
            Cliquez sur l'horaire que vous désirez ?
          </h2>
        </ion-row>
        <ion-row class="opacity jCenter" v-if="showPendingRdv == false">
          <template v-for="(slot, index) in state.slots.value" :key="index">
            <ion-button
              @click="confirm(slot)"
              v-if="slot.class == 'displayFlex'"
            >
              {{ slot.hours }} : {{ slot.minutes }}
            </ion-button>
          </template>
          <template v-if="pasDeRdv == 25">
            <h2 class="font30">
              Désolé mais il n y a plus de rendez vous disponible pour ce jour
            </h2>
          </template>
        </ion-row>
      </template>
      <template v-if="showRdvConfirm == true">
        <ion-button
          v-if="showBtnAccueil == true"
          @click="(showRdvConfirm = false), (showRdv = true)"
        >
          retour</ion-button
        >
        <ConfirmRdvUserNotConnected
          @closeaccueil="onShowBtnAccueil"
          :props="prop"
        />
      </template>
    </ion-card-content>
  </ion-card>
</template>

<script>
import getCollection from "@/composables/getCollection";
import ConfirmRdvUserNotConnected from "@/views/components/confirmRdv/ConfirmRdvUserNotConnected.vue";
import { IonCard, IonButton, IonRow } from "@ionic/vue";
import { ref, computed, onMounted } from "vue";
import * as moment from "moment";
import { useStore } from "vuex";
export default {
  components: {
    ConfirmRdvUserNotConnected,
    IonCard,
    IonRow,
    IonButton,
    // IonCardHeader,
    // IonCardContent,
  },
  setup() {
    moment.locale("fr");
    const showRdvAccueil = ref(true);
    const showRdv = ref(false);
    const showRdvConfirm = ref(false);
    const showBtnAccueil = ref(true);

    const blockActivate = ref(false);
    const store = useStore();

    const emailStore = computed(() => {
      return store.state.user.email;
    });

    const time = {
      now: ref(""),
      format: ref(""),
      dayNow: ref(""),
      dayNowFormat: ref(""),
    };
    //booleen si aucun slot de reservation possible
    const pasDeRdv = ref(0);
    const disponibilite = ref("");

    const state = {
      minutes: ref(0),
      hours: ref(8),
      interval: ref(30),
      hoursString: ref("0"),
      minutesString: ref("0"),
      slots: ref({
        minutes: 0,
        hours: 9,
        class: "displayFlex",
      }),
    };
    const { documents } = getCollection("patients", "total");

    time.now.value = moment();
    time.format.value = moment().format("dddd D MMMM YYYY");
    time.dayNow.value = moment().format("dddd D MMMM YYYY");

    const times = async () => {
      pasDeRdv.value = 0;
      //console.log("times");

      //on inititialise les slots
      state.slots.value = [
        {
          minutes: "00",
          hours: "08",
          class: "displayFlex",
        },
      ];

      while (state.hours.value < 20) {
        state.minutes.value = state.minutes.value + state.interval.value;
        if (state.minutes.value > 59) {
          state.hours.value = state.hours.value + 1;
          state.minutes.value = 60 - state.minutes.value;
        }
        if (state.minutes.value < 10) {
          state.minutesString.value = "0" + state.minutes.value;
        } else {
          state.minutesString.value = state.minutes.value;
        }
        if (state.hours.value < 10) {
          state.hoursString.value = "0" + state.hours.value;
        } else {
          state.hoursString.value = state.hours.value;
        }

        state.slots.value.push({
          hours: state.hoursString.value,
          minutes: state.minutesString.value,
          class: "displayFlex",
        });
        //console.log("tour");
      }
      // on redéfini this.hours
      state.hours.value = 8;
      //console.log(documents.value);
      await documents.value.forEach((element) => {
        if (element.jour == time.format.value) {
          state.slots.value.forEach((doc) => {
            const horaires = doc.hours + " : " + doc.minutes;
            if (horaires == element.heure) {
              doc.class = "displayNone";
            }
          });
        }
      });
    };
    const showPendingRdv = ref(false);

    const nbRdv = ref(0);
    const creneau = () => {
      nbRdv.value = 0;
      let nb = 0;
      documents.value.forEach((element) => {
        if (element.jour == time.format.value) {
          nb++;
        }
        nbRdv.value = 25 - nb;
      });
    };

    setTimeout(() => {
      creneau();
    }, 3000);

    // construction des différents slots fonction simple
    const showSlot = () => {
      showPendingRdv.value = true;
      setTimeout(() => {
        times();
        showPendingRdv.value = false;
      }, 1000);
      showRdv.value = true;
      showRdvAccueil.value = false;
    };

    const prop = ref("");
    const confirm = (data) => {
      prop.value = {
        jour: time.format.value,
        heure: data.hours + " : " + data.minutes,
      };
      showRdv.value = false;
      showRdvConfirm.value = true;
    };
    
    const reformatJour = () => {
        if (time.format.value.includes("dimanche") == true) {
        time.now.value = time.now.value.add(2, "d");
        time.format.value = time.now.value.format("dddd D MMMM YYYY");
        time.dayNow.value = time.format.value;
        //si on a dépassé la date du jour
      }
      if (time.format.value.includes("samedi") == true) {
        time.now.value = time.now.value.add(3, "d");
        time.format.value = time.now.value.format("dddd D MMMM YYYY");
        time.dayNow.value = time.format.value;
        //si on a dépassé la date du jour
      }
    }


    //add day
    const addDay = () => {
      times();
      time.now.value = time.now.value.add(1, "d");
      time.format.value = time.now.value.format("dddd D MMMM YYYY");
      if (time.format.value.includes("samedi") == true) {
        time.now.value = time.now.value.add(3, "d");
        time.format.value = time.now.value.format("dddd D MMMM YYYY");
        //si on a dépassé la date du jour
      } else if (time.format.value.includes("lundi") == true) {
        time.now.value = time.now.value.add(1, "d");
        time.format.value = time.now.value.format("dddd D MMMM YYYY");
        //si on a dépassé la date du jour
      }
      creneau();
    };

    const substractDay = () => {
      times();
      //console.log(time.dayNow.value);
      if (time.format.value == time.dayNow.value) {
        time.now.value = moment();
        time.format.value = time.now.value.format("dddd D MMMM YYYY");
        //console.log("aie aie aie");
        reformatJour()
      } else {
        if (time.format.value == time.dayNow.value) {
          time.now.value = moment();
          time.format.value = time.now.value.format("dddd D MMMM YYYY");
          //console.log("aie aie aie");
        } else {
          time.now.value = time.now.value.subtract(1, "d");
          time.format.value = time.now.value.format("dddd D MMMM YYYY");
          if (time.format.value.includes("dimanche") == true) {
            time.now.value = time.now.value.subtract(2, "d");
            time.format.value = time.now.value.format("dddd D MMMM YYYY");
            //si on a dépassé la date du jour
          }
        }
      }
      creneau();
    };

    const onShowBtnAccueil = () => {
      showBtnAccueil.value = false;
      //console.log(showBtnAccueil.value);
    };


    onMounted(() => {
      reformatJour()
    });

    return {
      nbRdv,
      blockActivate,
      emailStore,
      prop,
      showSlot,
      onShowBtnAccueil,
      showBtnAccueil,
      showRdvAccueil,
      showPendingRdv,
      documents,
      time,
      showRdv,
      showRdvConfirm,
      times,
      state,
      confirm,
      addDay,
      substractDay,
      pasDeRdv,
      disponibilite,
    };
  },
};
</script>

<style scoped>
/* 
ion-card {
  background-color:blueviolet
} */
.bloquerHoraire:focus {
  color: yellow !important;
}

h2 {
  text-align: center;
  color: var(--ion-color-primary);
}

.font30 {
  font-size: 30px;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.jCenter {
  justify-content: center;
}

.opacity {
  animation: opacity 1s forwards;
}

#opacity5 {
    animation: opacity5 3s forwards;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity5 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>