<template>
  <ion-row v-if="!user">
    Rendez vous le {{ props.jour }} à {{ props.heure }}</ion-row
  >
  <ion-card v-if="user">
    <ion-card-header v-if="showForm == true">
      <h3>Rendez vous le {{ props.jour }} à {{ props.heure }}</h3>
    </ion-card-header>
    <ion-card-content v-if="showForm == false && showReservation == false">
      patientez svp
    </ion-card-content>
    <ion-card-content v-if="showForm == true && credentials.reservation == 0">
      <h4>{{ user.email }}</h4>
      <ion-label color="secondary">Nom:</ion-label>
      <ion-input
        @keyup.enter="searchNameOnPatient(credentials.nom)"
        v-model="credentials.nom"
        :placeholder="credentials.nom"
      ></ion-input>
      <ion-button @click="searchNameOnPatient(credentials.nom)"
        >find</ion-button
      >
      <ion-button v-if="$store.state.searchPatient" @click="useSearch()"
        >use</ion-button
      >
      <ion-label color="secondary">Prenom : </ion-label>
      <ion-input
        v-model="credentials.prenom"
        :placeholder="credentials.prenom"
      ></ion-input>
      <ion-label color="secondary">Telephone : </ion-label>
      <ion-input
        v-model="credentials.telephone"
        :placeholder="credentials.telephone"
      ></ion-input>
      <ion-label color="secondary">Email : </ion-label>
      <ion-input
        v-model="credentials.email"
        :placeholder="credentials.email"
      ></ion-input>
      <h4>{{ errorSentence }}</h4>
      <ion-button
        v-if="showForm == true && credentials.reservation == 0"
        @click="onReservation"
      >
        Reserver Rdv</ion-button
      >
    </ion-card-content>
    <ion-card-content v-if="showForm == true && credentials.reservation == 1">
      vous avez deja un rdv , il faut l'annuler pour pouvoir reserver
    </ion-card-content>

    <ion-card-content v-if="showReservation == true">
      <div v-if="user.email == 'remipradere@gmail.com'">
        <ion-input v-model="credentials.telephone"></ion-input>
      </div>
      <h4 v-if="user.email != 'remipradere@gmail.com'">
        {{ credentials.nom }} {{ credentials.prenom }} : Vous avez reserver un
        rendez vous le {{ props.jour }} à {{ props.heure }} au cabinet de Condom
        situé au 4 bis rue Honoré cazaubon 32100 CONDOM
      </h4>
      <ion-textarea
        v-if="user.email == 'remipradere@gmail.com'"
        :value="sentenceConfirm"
      >
      </ion-textarea>
      <SendEmailJs :hello="infoMailJs"/>
    
      <ion-button v-if="user.email == 'remipradere@gmail.com'" id="buttonSms">
        <a :href="hrefGoogle" target="_blank" style="color: white !important">
          Google Agenda
        </a>
      </ion-button>
      <ion-button v-on:click="backMenu"> Accueil </ion-button>
    </ion-card-content>
  </ion-card>
  <ion-card v-if="!user">
    <ion-card-header>
      <h4>Vous n'etes pas connecté</h4>
    </ion-card-header>
    <ion-card-content>
      <p>appeler le 06.19.72.75.40</p>
    </ion-card-content>
  </ion-card>
</template>

<script>
import SendEmailJs from './SendEmailJs.vue'; 
import Modal from "./modal/ModalConfirm.vue";
import { computed, ref, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  modalController,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonInput,
  IonLabel,
} from "@ionic/vue";
import axios from "axios";
//importation de librairie email.js
import emailjs from 'emailjs-com';


import getCollectionWhere from "@/composables/getCollectionWhere";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import useCollection from "@/composables/useCollection";
import getCollection from "@/composables/getCollection";
export default defineComponent({
  props: ["props"],
  components: {
    SendEmailJs,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonLabel,
    IonInput,
  },
  methods: {},
  setup(props, context) {
    //info de l'utilisateur pour sendMailjs
    const infoMailJs = ref([]);


    const hrefGoogle = ref("");
    const store = useStore();
    let mail = "";
    let user1 = "";
    //console.log(store.state);
    if (store.state.user != null) {
      mail = store.state.user.email;
      user1 = store.state.user;
    } else {
      (mail = ""), (user1 = "");
    }
    const { document } = getCollectionWhere("list", mail);
    const user = computed(() => {
      return user1;
    });
    const credentials = ref({
      id: "",
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
    });
    const errorSentence = ref();
    const showForm = ref(false);
    const showReservation = ref(false);

    const sentenceConfirm = ref("");

    const toFormData = (obj) => {
      // conversion d'une données javascript

      const fd = new FormData();
      //console.log(obj);
      for (const i in obj) {
        fd.append(i, obj[i]);
      }
      //console.log(fd);
      // retourne le resultat
      return fd;
    };

    const sendAxios = (name) => {
      axios
        .post("https://www.remi-pradere.com/server/index.php?action=mail", name,{
           headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
        }
        })
        .then(function (response) {
          if (response.data.error) {
            //console.log(response);
          } else {
            //console.log(response.data);
            //console.log("success");
          }
        });
    };


    
     const sendEmailJs = () => {
       console.log(context);   
    };
  
  
  
        
        
      

    const sendMail = () => {
      const mail = {
        message: props.props.jour + " " + props.props.heure,
        nom: credentials.value.nom,
        prenom: credentials.value.prenom,
        telephone: credentials.value.telephone,
        email: credentials.value.email,
        jour: props.props.jour,
        heure: props.props.heure,
        total: props.props.jour + " " + props.props.heure,
        class: "displayFlex",
        createdAt: timestamp(),
        cle: "remipradere@gmail.com",
      };
      infoMailJs.value = mail;
      //console.log(mail);
      const data = toFormData(mail);
      sendAxios(data);
      //this.$router.push('./messageEnvoye')
    };





    const router = useRouter();
    setTimeout(() => {
      credentials.value.nom = document.value.nom;
      credentials.value.id = document.value.id;
      credentials.value.prenom = document.value.prenom;
      credentials.value.telephone = document.value.telephone;
      credentials.value.email = document.value.email;
      credentials.value.reservation = document.value.reservation;
      credentials.value.createdAt = document.value.createdAt;
      //console.log(credentials.value.id);
      showForm.value = true;
    }, 1000);
    const { addDoc } = useCollection("patients");

    const phraseGoogle = (data, datah, sentence, nom, prenom, telephone) => {
      console.log("googlePhrase");

      //let phrase = "mardi 5 octobre 2021";
      const phrase = data;
      //let hour = "11 : 30" + " 00"
      const hour = datah + " 00";
      const arrHour = hour.split(" ");

      const arr = phrase.split(" ");
      console.log(arr[2]);

      //rajout d'un zero
      if (arr[1] < 10) {
        arr[1] = "0" + arr[1];
      }
      console.log(arr[1]);

      //changement du mois en chiffre
      if (arr[2] == "janvier") {
        arr[2] = "01";
      }

      if (arr[2] == "fevrier") {
        arr[2] = "02";
      }

      if (arr[2] == "mars") {
        arr[2] = "03";
      }

      if (arr[2] == "avril") {
        arr[2] = "04";
      }

      if (arr[2] == "mai") {
        arr[2] = "05";
      }

      if (arr[2] == "juin") {
        arr[2] = "06";
      }

      if (arr[2] == "juillet") {
        arr[2] = "07";
      }

      if (arr[2] == "aout") {
        arr[2] = "08";
      }

      if (arr[2] == "septembre") {
        arr[2] = "09";
      }

      if (arr[2] == "octobre") {
        arr[2] = "10";
      }
      if (arr[2] == "novembre") {
        arr[2] = "11";
      }
      if (arr[2] == "decembre") {
        arr[2] = "11";
      }

      //date de départ
      if (arrHour[2] == 30) {
        arrHour[1] = parseInt(arrHour[0]) + 1;
        arrHour[3] = "00";
        if (arrHour[1] < 10) {
          arrHour[1] = "0" + arrHour[1];
          console.log(arrHour[1]);
        }

        console.log(arrHour[0]);
      } else {
        arrHour[1] = arrHour[0];
        arrHour[3] = "30";
      }

      console.log(arr[2]);

      console.log(arr[3] + arr[2] + arr[1] + "T" + arrHour[0] + arrHour[2]);
      const date1 = arr[3] + arr[2] + arr[1] + "T" + arrHour[0] + arrHour[2];
      console.log(arr[3] + arr[2] + arr[1] + "T" + arrHour[1] + arrHour[3]);
      const date2 = arr[3] + arr[2] + arr[1] + "T" + arrHour[1] + arrHour[3];
      console.log(`ceci est la date ${date1}`);
      console.log(date1,date2,sentence,nom,prenom,telephone)
      hrefGoogle.value = `https://calendar.google.com/calendar/u/0/r/eventedit?&
text=${nom}...${prenom}&
details=${sentence}&
location=4 Bis rue honore cazaubon&
dates=${date1}/${date2}&
details=${nom} ${prenom} ${telephone}&
sf=1&output=xml&pli=1`;
      console.log(hrefGoogle.value);
    };
    ///////////////////////////

    const onReservation = async () => {
      //console.log(credentials.value, credentials.value.nom, props.props);
      if (document.value.reservation == 0) {
        await addDoc({
          nom: credentials.value.nom,
          prenom: credentials.value.prenom,
          telephone: credentials.value.telephone,
          email: credentials.value.email,
          jour: props.props.jour,
          heure: props.props.heure,
          total: props.props.jour + " " + props.props.heure,
          class: "displayFlex",
          createdAt: timestamp(),
        });
        sendMail();
        const { updateDoc } = useDocument("list", credentials.value.id);
        if (document.value.email != "remipradere@gmail.com") {
          await updateDoc({
            id: credentials.value.id,
            reservation: 1,
          });
        } else {
          sentenceConfirm.value =
            credentials.value.nom +
            " " +
            credentials.value.prenom +
            "  : PRADERE Rémi Podologue :  Vous avez reserver un rendez vous le " +
            props.props.jour +
            " à " +
            props.props.heure +
            " au cabinet de Condom situé au 4 bis rue Honoré cazaubon 32100 CONDOM  plus d'infos https://www.remipraderepedicurepodologue.com";
          phraseGoogle(
            props.props.jour,
            props.props.heure,
            sentenceConfirm.value,
            credentials.value.nom,
            credentials.value.prenom,
            credentials.value.telephone
          );
        }
        showReservation.value = true;
        showForm.value = false;
        context.emit("closeaccueil");
      } else {
        errorSentence.value = "vous avez deja un rdv";
      }
    };
    const backMenu = () => {
      router.push({ path: "Inbox" });
    };

    //phrase de rapel de googleAgenda

    const { documents } = getCollection("patients", "total");

    const useSearch = () => {
      credentials.value.nom = store.state.searchPatient.nomInput;
      credentials.value.prenom = store.state.searchPatient.prenomInput;
      credentials.value.telephone = store.state.searchPatient.phoneInput;
    };

    const input = ref("");

    const searchNameOnPatient = (data) => {
      let stop = 0;
      const results = [];
      //console.log(data.toLowerCase());
      documents.value.forEach((element) => {
        const name = element.nom.toLowerCase();

        const inputLowerCase = data.toLowerCase();

        if (name.includes(inputLowerCase) && stop != 6) {
          results.push({
            idInput: element.id,
            nomInput: element.nom,
            prenomInput: element.prenom,
            phoneInput: element.telephone,
          });
          stop++;
        }
        input.value = results;
      });
      //console.log(input.value.nomInput, input.value.phoneInput);
      modalController
        .create({
          component: Modal,
          componentProps: {
            title: "patient",
            content: input.value,
          },
        })
        .then((m) => m.present());
    };
    return {
      infoMailJs,
      sendMail,
      sendEmailJs,
      useSearch,
      sentenceConfirm,
      input,
      searchNameOnPatient,
      backMenu,
      showReservation,
      showForm,
      onReservation,
      user,
      credentials,
      errorSentence,
      hrefGoogle,
    };
  },
});
</script>

<style scoped>
ion-input {
  --color: var(--ion-color-primary);
  font-size: 20px;
}
.font30 {
  font-size: 30px;
}
h2,
h3 {
  font-size: 1.6em !important;
}
h4 {
  font-size: 1.5em !important;
}
ion-label {
  font-size: 1.4em !important;
}
</style>