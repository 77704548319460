
import { ref } from "vue";
import {IonButton,IonItem,IonInput} from "@ionic/vue"
export default {
    components:{
        IonItem,IonInput,IonButton
    },
  emits: ["do-login"],
  setup() {
    const loading = ref(false)
    const credentials = ref<any>(
      {
        email: "",
        password: "",
      });
    return { loading,credentials };
  },
};
