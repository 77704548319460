<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary-contrast"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{
          $route.params.id == "Inbox" ? "Accueil" : $route.params.id
        }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-text class="ion-text-center" color="danger">
         <h4></h4>
      </ion-text>
      <div v-if="$route.params.id == 'Inbox'">
        <div v-if="authChecked"></div>
        <div class="ion-text-center" v-else-if="!user">
          <h1>Rémi Pradere pédicure Podologue</h1>
          <template v-if="show.login == true">
            <ion-button
              @click="
                (show.login = false),
                  (show.signup = false),
                  (show.presentation = true)
              "
            >
              retour</ion-button
            >
            <h2>Connectez Vous à votre compte</h2>
            <LoginPanel @do-login="doLogin" />
          </template>
          <template v-if="show.signup == true">
            <ion-button
              @click="
                (show.login = false),
                  (show.signup = false),
                  (show.presentation = true)
              "
            >
              retour</ion-button
            >
            <h2>
              Inscrivez vous pour pouvoir prendre rendez vous avec plus de
              faciité
            </h2>
            <SignIn />
          </template>
          <template v-if="show.presentation">
            <h2>Vous pouvez prendre Rendez vous sans inscription</h2>
            <ion-button @click="onRdvSansConnexion"> Prendre Rdv</ion-button>
            <h2>
              Mais pour plus de faciliter d'utilisation, nous vous conseillons
              de vous connecter ou vous inscrire?
            </h2>
            <ion-button
              @click="
                (show.login = true),
                  (show.signup = false),
                  (show.presentation = false)
              "
            >
              Se connecter</ion-button
            >
            <ion-button
              @click="
                (show.login = false),
                  (show.signup = true),
                  (show.presentation = false)
              "
            >
              S'inscrire</ion-button
            >
          </template>
        </div>
        <div v-else>
          <Home @do-logout="doLoggout" :user="user" />
        <div v-show="user.email != 'remipradere@gmail.com'" >
          <ion-row v-for="(doc, index) in documents" :key="index">        
            <ion-card v-show="doc.email == user.email">
              <ion-card-header>
                <ion-card-title class="opacity">
                  {{ doc.nom }} {{ doc.prenom }}, vous avez un rendez vous le
                  {{ doc.jour }} à {{ doc.heure }} , au 4 bis rue Honoré
                  Cazaubon 32100 CONDOM
                </ion-card-title>
                <ion-card-subtitle v-if="user.email == 'remipradere@gmail.com'"
                  >{{ doc.telephone }}
                </ion-card-subtitle>
              </ion-card-header>
              <ion-card-content class="opacity">
                <ion-button
                  v-if="show.cancel == false"
                  @click="show.cancel = true"
                >
                  Annuler
                </ion-button>
                <ion-card-title v-show="show.cancel == true">
                  Voulez vous annuler votre rdv?
                  <ion-button @click="deleteRdv(doc.id)"> Oui </ion-button>
                  <ion-button @click="show.cancel = false"> Non </ion-button>
                </ion-card-title>

                <ion-button v-if="show.cancel == false">
                  Google agenda
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-row>
          </div>
        </div>
      </div>

      <div
        v-if="
          $route.params.id == 'agenda' && $store.state.user.email == 'remipradere@gmail.com'
        "
      >
        <div v-if="authChecked"></div>
        <div v-else-if="!user">Vous n'etes pas connecté</div>
        <div v-else>
          <ion-buttons>
            <ion-button @click="backHome"> Retour </ion-button>
          </ion-buttons>
          <AccueilAgenda />
        </div>
      </div>

      <div v-if="$route.params.id == 'horaires'">
        <div v-if="authChecked"></div>
        <div v-else-if="!user">Vous n'etes pas connecté</div>
        <div v-else>
          <Rdv />
        </div>
      </div>
      <div v-if="$route.params.id == 'rdvsansconnexion'">
        <RdvSansConnexion />
      </div>

      
      <div v-if="$route.params.id == 'list'">
      
         <ListRdv/>
        
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import getCollection from "../composables/getCollection";

import SignIn from "@/views/components/SignIn.vue";
import Home from "@/views/Home.vue";
import RdvSansConnexion from "@/views/RdvSansConnexion.vue";
import Rdv from "@/views/components/Rdv.vue";
import AccueilAgenda from "@/views/AccueilAgenda.vue";
import LoginPanel from "@/views/components/LoginPanel.vue";
import ListRdv from "@/views/ListRdv.vue";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { computed, ref,onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useDocument from "@/composables/useDocument";
export default {
  name: "Folder",
  components: {
    Rdv,
    ListRdv,
    SignIn,
    AccueilAgenda,
    RdvSansConnexion,
    Home,
    LoginPanel,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const { documents } = getCollection("patients", "total");
    const { documents: list } = getCollection("list", "nom");
    const store = useStore();
    const router = useRouter();
    const show = ref({
      login: false,
      signup: false,
      cancel: false,
      presentation: true,
    });
    const backHome = () => {
      router.push("/folder/Inbox");
    };

    const onRdvSansConnexion = () => {
      router.push({ name: "Folder", params: { id: "rdvsansconnexion" } });
    };

    const user = computed(() => {
      return store.state.user;
    });
    const userEmailId = computed(() => {
      let userId = "";
      //console.log(list.value);
      if (list.value != null) {
        list.value.forEach((element) => {
          if (element.email == store.state.user.email) {
            userId = element.id;
          }
        });
      }
      return userId;
    });

    const authChecked = computed(() => {
      return store.state.initialized;
    });
    const doLogin = async (credentials) => {
      try {
        await store.dispatch("login", { ...credentials });
      } catch (e) {
        alert(e);
      }
    };
    const doLoggout = async () => {
      await store.dispatch("logout");
    };
    const deleteRdv = async (id) => {
      //console.log(userEmailId.value);
      const { deleteDoc } = useDocument("patients", id);
      const { updateDoc } = useDocument("list", userEmailId.value);
      await deleteDoc();
      await updateDoc({ reservation: 0 });
    };

   setTimeout(()=>{
        store.dispatch('getCollection',documents.value)
   },4000)

    return {
      userEmailId,
      documents,
      show,
      user,
      authChecked,
      doLogin,
      doLoggout,
      backHome,
      deleteRdv,
      onRdvSansConnexion,
    };
  },
};
</script>

<style scoped>
ion-content {
  --backgroud: var(--ion-color-success);
}

.bcaquamarine {
  background-color: antiquewhite !important;
}
</style>