// import de project auth
import { projectAuth } from '../firebase/config'
import { ref } from 'vue';

//initialisation de l'erreur
const error = ref(null)
const isPending = ref(false)
    //fonction pour faire inscrire 
const signup = async(email, password, nom) => {
    error.value = null;
    isPending.value = true
    try {
        const res = await projectAuth.createUserWithEmailAndPassword(email, password)
            //si pas de reponse 
        if (!res) {
            throw new Error('could not complete the signUp')
        }
        //on ajoute ici car create ne prends que l'email et le password
        await res.user.updateProfile({ nom })
        error.value = null
        isPending.value = false
        return res
    } catch (err) {
        //console.log(err)
        //on affiche le message d'erreur
        isPending.value = false
        error.value = err.message
    }
}

const useSignup = () => {
    return { error, signup, isPending }
}

export default useSignup