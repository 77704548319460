<template>
  <ion-loading
    :is-open="isOpenRef"
    cssClass="my-custom-class"
    message="Please wait..."
    duration="5000"
    @didDismiss="setOpen(false)"
  >
  </ion-loading>
  <ion-row v-if="user">
    Attention vous etes dans la partie non enregistré</ion-row
  >
  <ion-card v-else>
    <ion-card-header v-if="showForm == true">
      <h3 class="ion-color-primary font30 ion-text-center">
        Remplissez pour confirmer le rdv le {{ props.jour }} à {{ props.heure }}
      </h3>
    </ion-card-header>

    <ion-card-content
      v-if="
        showForm == false &&
        showReservation == false &&
        showReservationPending == false
      "
    >
      patientez  un peu svp
    </ion-card-content>

    <ion-card-content v-if="showForm == true">
      <ion-label>Nom :</ion-label>
      <ion-input
        required
        v-model="credentials.nom"
        placeholder="Votre Nom"
      ></ion-input>

      <ion-label color="secondary">Prenom : </ion-label>
      <ion-input
        required
        v-model="credentials.prenom"
        placeholder="Votre Prenom"
      ></ion-input>
      <ion-label color="secondary">Telephone portable : </ion-label>
      <ion-input
        required
        v-model="credentials.telephone"
        placeholder="Telephone : 0606060606"
      ></ion-input>
      <ion-label color="secondary">Email : </ion-label>
      <ion-input
        v-model="credentials.email"
        :placeholder="credentials.email"
      ></ion-input>
      <ion-text color="danger" class="ion-text-center">
        <h4>{{ errorSentence }}</h4></ion-text
      >
      <ion-button expand v-if="showForm == true" @click="onReservation">
        Reserver Rdv</ion-button
      >
    </ion-card-content>

    <ion-card-content class="opacity" v-if="showReservationPending == true">
      <h2>Attention</h2>
      <p>
        Vu que vou n'etes pas inscrit , vous ne recevrez pas de confirmation de
        rendez vous par email
      </p>
      <h3>Votre reservation va s'afficher dans quelques instants</h3>
    </ion-card-content>

    <ion-card-content class="opacity" v-if="showReservation == true">
      <h4>
        PRADERE Rémi Podologue :
        {{ credentials.nom }} {{ credentials.prenom }} : Vous avez reserver un
        rendez vous le {{ props.jour }} à {{ props.heure }} au cabinet de Condom
        situé au 4 bis rue Honoré cazaubon 32100 CONDOM
      </h4>
      <ion-button v-on:click="backMenu"> Accueil </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import {
  IonLoading,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonInput,
  IonLabel,
} from "@ionic/vue";

import { timestamp } from "@/firebase/config";
import useCollection from "@/composables/useCollection";

export default defineComponent({
  props: ["props"],
  components: {
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonLabel,
    IonInput,
    IonLoading,
  },
  setup(props, context) {
    const credentials = ref({
      id: "",
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
    });
    const errorSentence = ref();
    const showForm = ref(false);
    const showReservation = ref(false);
    const showReservationPending = ref(false);


     const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;




    const toFormData = (obj) => {
      // conversion d'une données javascript

      const fd = new FormData();
      //console.log(obj);
      for (const i in obj) {
        fd.append(i, obj[i]);
      }
      //console.log(fd);
      // retourne le resultat
      return fd;
    };

    const sendAxios = (name) => {
      axios
        .post("https://www.remi-pradere.com/server/index.php?action=mail", 
          name,
          { headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS"
        }})
        .then(function (response) {
          if (response.data.error) {
            //console.log(response);
          } else {
            //console.log(response.data);
            //console.log("success");
          }
        });
    };

    const sendMail = () => {
      const mail = {
        message: props.props.jour + " " + props.props.heure,
        nom: credentials.value.nom,
        prenom: credentials.value.prenom,
        telephone: credentials.value.telephone,
        email: credentials.value.email,
        jour: props.props.jour,
        heure: props.props.heure,
        total: props.props.jour + " " + props.props.heure,
        class: "displayFlex",
        createdAt: timestamp(),
        cle: "remipradere@gmail.com",
      };
      //console.log(mail);
      const data = toFormData(mail);
      sendAxios(data);
    };

    const router = useRouter();
    setTimeout(() => {
      showForm.value = true;
    }, 1000);
    const { addDoc } = useCollection("patients");

    const onReservation = async () => {
      if (
        credentials.value.nom != "" &&
        credentials.value.prenom != "" &&
        credentials.value.telephone != ""
      ) {
        showForm.value = false;
        showReservationPending.value = true;
        if (
          credentials.value.nom != "" &&
          credentials.value.prenom != "" &&
          credentials.value.telephone != ""
        ) {
          await addDoc({
            nom: credentials.value.nom,
            prenom: credentials.value.prenom,
            telephone: credentials.value.telephone,
            email: "remipradere@gmail.com",
            jour: props.props.jour,
            heure: props.props.heure,
            total: props.props.jour + " " + props.props.heure,
            class: "displayFlex",
            createdAt: timestamp(),
          });
          sendMail();
          setTimeout(() => {
            showReservation.value = true;
            showReservationPending.value = false;
            context.emit("closeaccueil");
          }, 6000);
        } else {
          errorSentence.value = "vous avez deja un rdv";
        }
      } else {
        errorSentence.value = " vous n'avez pas remplie tous les champs";
      }
    };
    const backMenu = () => {
      router.push({ path: "Inbox" });
    };
    return {
      backMenu,
      showReservation,
      showReservationPending,
      showForm,
      onReservation,
      credentials,
      errorSentence,
      setOpen
    };
  },
});
</script>

<style scoped>
.font30 {
  font-size: 30px;
}

h3,
ion-label {
  color: navy !important;
}
h2,
h3 {
  font-size: 1.6em !important;
}
h4 {
  font-size: 1.5em !important;
}
ion-label {
  font-size: 1.4em !important;
}

ion-input {
  border: 1px solid grey;
  border-radius: 10px;
}
</style>